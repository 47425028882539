import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const notify = () =>
  toast.success(
    "Bilgileriniz gönderildi en kısa süre içinde sizinle iletişime geçilecektir.",
    {
      style: {
        border: "1px solid #50C878",
        padding: "16px",
        color: "#50C878",
      },
      iconTheme: {
        primary: "#50C878",
        secondary: "#FFFAEE",
      },
    }
  );

const Contact = () => {
  const [buttonVisibleStatus, setbuttonVisibleStatus] = useState(true);

  function handleSubmit(event) {
    event.preventDefault();
    notify();
    emailjs
      .sendForm(
        "service_g7tal9z",
        "template_ef90qbf",
        event.target,
        "ji3y09qmH6b8D6gju"
      )
      .then(
        (result) => {
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  function ReCAPTCHAonChange(value) {
    console.log("Captcha value:", value);
    setbuttonVisibleStatus(false);
  }

  return (
    <React.Fragment>
      <section className="contact overflow-hidden" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">İLETİŞİM</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg={8}>
              <div className="contact-box">
                <div className="custom-form mt-4">
                  <form method="post" name="myForm" onSubmit={handleSubmit}>
                    <p id="error-msg" style={{ opacity: 1 }}>
                      {" "}
                    </p>
                    <div id="simple-msg"></div>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control contact-form"
                            placeholder="Adınız ve Soyadınız"
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form-group">
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control contact-form"
                            placeholder="Mail adresiniz"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control contact-form"
                            id="subject"
                            name="subject"
                            placeholder="Başlık"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <textarea
                            name="comments"
                            id="comments"
                            rows="6"
                            className="form-control contact-form h-auto"
                            placeholder="İçerik"
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <ReCAPTCHA
                          sitekey="6LfQ9sApAAAAAPpyoWzV97RlzM4jh4IZ-z7KGx1a"
                          onChange={() => ReCAPTCHAonChange()}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-center mt-3">
                      <div className="col-lg-6">
                        <div className="text-center">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-rounded bg-gradiant"
                            value="Mesaj Gönder"
                            disabled={buttonVisibleStatus}
                          />
                        </div>
                      </div>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="my-5 py-4 justify-content-center text-center">
            <Col md={4}>
              <div>
                <i className="mdi mdi-google-maps f-50 text-primary"></i>
                <h5 className="mb-1">FixCenter</h5>
                <p className="f-14 mb-0 text-muted">
                  Konak, İsmet Kaptan Mah. Fevzi Paşa Bulvarı, 1363 sok.
                  no:14/B-03 Yetkin İş Merkezi Çankaya, 35210 İzmir
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="mt-4 mt-lg-0">
                <i className="mdi mdi-email f-50 text-primary"></i>
                <h5 className="mb-1">Email</h5>
                <p className="f-14 mb-0 text-muted">destek@fixcenter.com.tr</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="mt-4 mt-lg-0">
                <i className="mdi mdi-phone f-50 text-primary"></i>
                <h5 className="mb-1">Telefon</h5>
                <p className="f-14 mb-0 text-muted">
                  <Link to="https://wa.me/905102210100">+90 510 221 01 00</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="position-relative">
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3125.794903616216!2d27.1398063!3d38.4231044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd8f0f3119b43%3A0xd9bd48bf4df892be!2sFixCenter!5e0!3m2!1str!2str!4v1713248756256!5m2!1str!2str"
              width="100%"
              height="450"
              title="myFrame"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
