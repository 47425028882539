import React from "react";
import AboutUs from '../components/AboutUs'
import Features from '../components/Features'
import Contact from '../components/Contact'
import Footer from "./Footer";


const Layout = ({ children }) => {
    return (
        <React.Fragment>
            {children}
            <AboutUs />
            <Features />
            {/* <Work /> */}
            {/* <Pricing />
            <Application />
            <Team /> */}
            <Contact />
            <Footer />
        </React.Fragment>
    )
}

export default Layout;