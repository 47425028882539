import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';

// import images
import mTop from "../assets/images/about/macbook-top.svg";
import mCover from "../assets/images/about/macbook-cover.svg";
import about from "../assets/images/about/about.mp4";
import mBottom from "../assets/images/about/macbook-bottom.svg";

AOS.init({
  duration: 1800,
});

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section about" id="about">
        <Container>
          <Row className="justify-content-center mb-0">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">Hakkımızda</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="text-center">
            <Col lg={4} md={12} className="mb-5">
              <div className="mockup mockup-macbook loaded opened">
                <div className="">
                  <img src={mTop} alt="" className="img-fluid top" />
                  <img src={mCover} alt="" className="img-fluid cover" />
                  <video autoPlay muted controls loop>
                    <source src={about} type="video/mp4" />
                  </video>
                </div>
                <div className="part bottom">
                  <img src={mCover} alt="" className="img-fluid cover" />
                  <img src={mBottom} alt="" className="img-fluid bottom" />
                </div>
              </div>
            </Col>
            <Col
              lg={4}
              md={6}
              className="d-flex text-center justify-content-center "
            >
              <div className="features-box text-center">
                <div className="text-center justify-content-center">
                  <h5 className="f-15 text-uppercase">MİSYONUMUZ</h5>
                  <p className="text-muted mt-3">
                  Şirketimiz, sektördeki uzun yıllara dayanan tecrübesiyle kaliteli ürün ve hizmetler sunmayı ilke edinmiştir. Müşteri memnuniyetini her şeyin önünde tutarak güvenilirliği ve kaliteyi bir arada sunarak müşterilerimizin beklentilerini en üst düzeyde karşılamayı hedefliyoruz.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="features-box text-center">
                <div className="text-center justify-content-center">
                  <h5 className="f-15 text-uppercase">VİZYONUMUZ</h5>
                  <p className="text-muted mt-3">
                  Şirketimiz, kaliteden ödün vermeyen ve güvenilirliği ön planda tutan bir yaklaşımla, sürekli olarak yenilikçi çözümler sunar. Sürdürülebilirlik ve doğaya olan saygı, iş yapma kültürümüzün temelini oluştururken, gelecek nesillere daha iyi bir dünya bırakma amacımızı da yansıtır.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
